import { styled } from '@/stitches.config';

export const FillAndCenter = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  flexGrow: 1,
  width: '100%',
  position: 'relative',
  margin: '0 auto',
});