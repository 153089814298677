import { FillAndCenter } from '../layout/FillAndCenter';
import { Stack } from '../layout/Stack';
import { Text } from '../typography/Text';
import { Spinner } from './Spinner';
import { styled } from '@/stitches.config';

const FullHeightCenter = styled(FillAndCenter, {
  minHeight: '100%',
  height: '100vh',
});

const ErrorContainer = styled('div', {
  padding: '20px',
  borderRadius: '8px',
  backgroundColor: 'rgba(255, 0, 0, 0.1)',
  maxWidth: '600px',
  width: '90%',
  margin: '0 auto',
});

interface LoadingProps {
  message?: string;
  error: unknown;
  errorMessage: string;
}

export function Loading({ message, error, errorMessage }: LoadingProps) {
  const hasError = error !== undefined && error !== null;

  return (
    <FullHeightCenter>
      {!hasError ? (
        <Stack spacing="large" align="center">
          <Spinner size="large" />
          {message && (
            <Text weight="medium" size="large">
              {message}
            </Text>
          )}
        </Stack>
      ) : (
        <ErrorContainer>
          <Stack spacing="medium">
            <Text weight="semibold" color="error">
              {errorMessage}
            </Text>
            {process.env.NODE_ENV === 'development' && (
              <Text as="pre" size="small" css={{ whiteSpace: 'pre-wrap' }}>
                Error (Dev Only): {error.toString()}
              </Text>
            )}
          </Stack>
        </ErrorContainer>
      )}
    </FullHeightCenter>
  );
}