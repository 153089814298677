import React, { FC } from 'react';
import cn from 'classnames';

interface TextProps {
  children: React.ReactNode;
  as?: keyof JSX.IntrinsicElements;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | '6xl';
  weight?: 'light' | 'normal' | 'medium' | 'semibold' | 'bold';
  color?: 'primary' | 'secondary' | 'tertiary' | 'accentPrimary' | 'accentSecondary' | 'accentTertiary' | 'accent' | 'inverted' | 'positive';
  tracking?: 'tight' | 'normal';
  align?: 'left' | 'center' | 'right';
  textTransform?: 'none' | 'uppercase';
  textOverflow?: 'ellipsis';
  overflowWrap?: 'breakWord';
  whiteSpace?: 'noWrap';
  maxLines?: 1 | 2 | 3;
  className?: string;
}

const sizeClasses = {
  xs: 'text-xs',
  sm: 'text-sm',
  md: 'text-base',
  lg: 'text-lg',
  xl: 'text-xl',
  '2xl': 'text-2xl',
  '3xl': 'text-3xl',
  '4xl': 'text-3xl lg:text-4xl',
  '5xl': 'text-4xl lg:text-5xl',
  '6xl': 'text-4xl lg:text-6xl'
};

const weightClasses = {
  light: 'font-[420]',
  normal: 'font-[450]',
  medium: 'font-[475]',
  semibold: 'font-[540]',
  bold: 'font-[570]'
};

const colorClasses = {
  default: 'text-inherit',
  primary: 'text-silvermist-950',
  secondary: 'text-silvermist-950/60',
  tertiary: 'text-silvermist-950/40',
  accentPrimary: 'text-lupine-950',
  accentSecondary: 'text-lupine-950/60',
  accentTertiary: 'text-lupine-950/40',
  accent: 'text-lupine-600',
  inverted: 'text-white',
  positive: 'text-pineglade-600'
};

const trackingClasses = {
  tight: 'tracking-tight',
  normal: 'tracking-normal'
};

const alignClasses = {
    left: 'text-left',
    center: 'text-center',
    right: 'text-right',
};

const textTransformClasses = {
    none: '',
    uppercase: 'uppercase',
};

const textOverflowClasses = {
    ellipsis: 'truncate',
};

const overflowWrapClasses = {
    breakWord: 'break-words',
};

const whiteSpaceClasses = {
    noWrap: 'whitespace-nowrap',
};

const maxLinesClasses = {
    1: 'line-clamp-1',
    2: 'line-clamp-2',
    3: 'line-clamp-3',
};

const Text: FC<TextProps> = ({
    children,
    as: Tag = 'span',
    size = 'md',
    weight = 'normal',
    color = 'default',
    tracking = 'normal',
    align = 'left',
    textTransform = 'none',
    textOverflow,
    overflowWrap,
    whiteSpace,
    maxLines,
    className
}) => {
    const classes = cn(
        'font-marketing',
        sizeClasses[size],
        weightClasses[weight],
        colorClasses[color],
        trackingClasses[tracking],
        alignClasses[align],
        textTransformClasses[textTransform],
        textOverflowClasses[textOverflow],
        overflowWrapClasses[overflowWrap],
        whiteSpaceClasses[whiteSpace],
        maxLinesClasses[maxLines],
        className
    );

  return <Tag className={classes}>{children}</Tag>;
};

export default Text;