import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import updateLocale from 'dayjs/plugin/updateLocale';
import utc from 'dayjs/plugin/utc';

export const dayjsInitialization = () => {
  dayjs.extend(relativeTime);
  dayjs.extend(updateLocale);
  dayjs.extend(utc);
  dayjs.extend(timezone);
  dayjs.extend(duration);
  dayjs.updateLocale('en', {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: '<1m',
      m: '1m',
      mm: '%dm',
      h: '1h',
      hh: '%dh',
      d: '1d',
      dd: '%dd',
      M: '1mo',
      MM: '%dmos',
      y: '1y',
      yy: '%dy',
    },
  });
};

export const formatUTCDate = (date: string) => dayjs(date).format('MM/DD/YYYY');

export const formatUTCDateFull = (date: string) =>
  new Date(date).toLocaleString();

export const formatUTCDateFromNow = (date: string) => {
  return dayjs.utc(date).local().fromNow();
};

export const formatUTCDiff = (start: string, end: string): string => {
  const hours = dayjs(end).diff(start, 'hours', false);
  const minutes = dayjs(end).diff(start, 'minutes', false);
  const seconds = Math.ceil(dayjs(end).diff(start, 'seconds', true));

  const result: string[] = [];
  if (hours) result.push(`${hours}h`);
  if (minutes) result.push(`${minutes - hours * 60}m`);
  if (seconds) result.push(`${seconds - minutes * 60}s`);
  return result.join(' ');
};

export const formatDuration = (seconds: number): string => {
  const timeDuration = dayjs.duration(seconds, 'seconds');
  const days = timeDuration.days();
  const hours = timeDuration.hours();
  const minutes = timeDuration.minutes();

  const result: string[] = [];
  if (days) result.push(`${days}d`);
  if (hours) result.push(`${hours}h`);
  if (minutes) result.push(`${minutes}m`);
  return result.join(' ');
};
