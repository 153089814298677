import React from 'react';
import Image from 'next/image';
import { MarketingPage } from './MarketingPage';
import { Meta } from '../Meta';
import { MarketingBanner } from './MarketingBanner';
import CompanyLogosSection from '@/components/marketing/LogoGrid';
import { redirectToLoginOrDashboard } from '@/utils/url';
import Link from 'next/link';
import { CTABox } from '@/components/marketing/CTABox';
import Section from '@/components/marketing/Section';
import { DEMO_URL } from '@/components/blog/DemoLink';

// Constants
const KEY_LINE = 'We help your sales teams become product experts instantly';
const SUBTITLE = 'Purpose-built for Revenue Teams';
const CTA_GET_DEMO = "Get a Demo";
const CTA_SIGN_UP = "Sign Up for Free";

const HeroContent = ({ title, url }) => (
  <div className='flex flex-col items-center w-full max-w-6xl mx-auto bg-white px-4 sm:px-6 lg:px-8 pt-8'>
    <MarketingBanner title={title} link={url}/>
    <h1 className="font-general-sans text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-center mt-8 sm:mt-12 mb-6 sm:mb-6">
      {KEY_LINE}
    </h1>
    <h2 className="font-general-sans text-md sm:text-xl md:text-2xl lg:text-3xl font-semibold text-center text-lupine-400 mb-6 sm:mb-8">
      {SUBTITLE}
    </h2>
    <p className="font-general-sans text-lg sm:text-xl md:text-2xl text-center text-gray-600 max-w-3xl mb-6 sm:mb-8">
      Wolfia gives your sales team superpowers with instant, expert-quality knowledge through our AI agent which collects knowledge in the background, accelerating your sales process like never before.
    </p>
    <div className="flex flex-col sm:flex-row gap-4 mb-8 sm:mb-12 w-full sm:w-auto">
      <Link
        href={redirectToLoginOrDashboard()}
        className="w-full sm:w-auto px-6 py-3 text-base font-semibold text-white bg-black rounded-full hover:text-white text-center"
      >
        {CTA_SIGN_UP}
      </Link>
      <Link
        href={DEMO_URL}
        className="w-full sm:w-auto px-6 py-3 text-base font-semibold text-black bg-white border border-black-800 rounded-full hover:text-black text-center"
      >
        {CTA_GET_DEMO}
      </Link>
    </div>
    <div className="w-full max-w-7xl p-2 sm:p-3 border-2 border-silvermist-100 bg-silvermist-100">
      <div className="border border-silvermist-100 rounded-lg sm:rounded-2xl overflow-hidden">
        <Image
          src="/images/home/wolfia-hero-image.png"
          alt="Wolfia Platform Preview"
          width={1440}
          height={1080}
          layout="responsive"
          objectFit="cover"
        />
      </div>
    </div>
  </div>
);

const HeroContainer = ({ title, url }) => (
  <div className="flex flex-col w-full max-w-screen bg-white">
    <div className="max-w-8xl mx-auto px-4 sm:px-6 xl:px-10">
      <HeroContent title={title} url={url} />
    </div>
    <div className="max-w-8xl mx-auto px-4 sm:px-6 xl:px-10">
      <CompanyLogosSection/>
    </div>
  </div>
);

const TestimonialBlock = ({
  quote,
  name,
  title,
  description,
  image,
  featured,
  companySlug,
}) => (
  <div
    className={`
      flex flex-col justify-between 
      rounded-xl sm:rounded-2xl 
      ${featured ? 'p-6 sm:p-8' : 'p-5 sm:p-6'}
      shadow-sm hover:shadow-md 
      transition-shadow duration-300 
      ${featured ? 'col-span-1 lg:col-span-3' : ''}
      ${featured
      ? 'bg-gradient-to-br from-white via-white to-lupine-50 border-2 border-lupine-200'
      : 'bg-white border border-gray-200'
    }
    `}
  >
    <div
      className={`flex justify-between items-center ${
        featured ? 'mb-8' : 'mb-6'
      }`}
    >
      {featured && (
        <span className="inline-flex items-center px-3 py-1 text-sm font-medium text-lupine-700 bg-lupine-100 rounded-full">
          Featured
        </span>
      )}
      {featured && (
        <div className={`relative w-32 h-12`}>
          <Image
            src={`/images/logos/companies/${companySlug}.png`}
            alt={`${companySlug} logo`}
            width={200}
            height={100}
            className="w-full h-full object-contain"
          />
        </div>
      )}
    </div>
    <div
      className={`
        relative
        ${featured ? 'text-lg sm:text-xl leading-relaxed' : 'text-base leading-relaxed'} 
        text-gray-600 
        ${featured ? 'mb-8' : 'mb-6'}
        ${featured ? 'px-1' : ''}
      `}
    >
      <span className="relative z-10">{quote}</span>
    </div>
    <div className="flex items-center mt-auto">
      <div className={`relative ${featured ? 'h-14 w-14' : 'h-12 w-12'}`}>
        <Image
          src={image}
          alt={`Portrait of ${name}`}
          fill
          className="rounded-full object-cover"
        />
      </div>
      <div className="ml-4 flex flex-col">
        <div
          className={`
            font-semibold text-gray-900 
            ${featured ? 'text-lg' : 'text-base'}
            tracking-tight
          `}
        >
          {name}
        </div>
        <div
          className={`
            ${featured ? 'text-base' : 'text-sm'} 
            text-gray-600
            tracking-tight
          `}
        >
          {title}
        </div>
        {description && (
          <div className="text-sm text-gray-500 mt-0.5 tracking-tight">
            {description}
          </div>
        )}
      </div>
    </div>
  </div>
);

const TestimonialsModule = () => (
  <section className="bg-white px-4 sm:px-6 py-8 sm:py-12 md:py-16 lg:py-20">
    <div className="max-w-7xl mx-auto">
      <div className='text-center mb-8 sm:mb-12'>
        <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-2">Trusted by modern companies</h2>
      </div>
      <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8'>
        <TestimonialBlock
          quote="When our team member left, I thought I'd be buried in security questionnaires. Instead, Wolfia's AI agent helped me handle 4X our usual volume. The sales team thinks it's magic - they send in a questionnaire and get responses back the same day. I've gone from spending hours writing answers to just reviewing what the AI generates. It's completely transformed our security review process."
          name="Garrett Close"
          title="Head of GRC · Amplitude"
          image="/images/home/landingPage_testimonials_portrait_garrett.png"
          featured={true}
          companySlug="amplitude"
        />
        <TestimonialBlock
          quote="Nobody enjoys filling out security questionnaires and RFPs. Wolfia has dramatically reduced the repetitive work involved in handling security questionnaires and RFPs. It feels like having superpowers—I can tackle lengthy proposals swiftly without constantly relying on our subject matter experts."
          name="Parker Lewis"
          title="Account Executive · Finley"
          image="/images/home/landingPage_testimonials_portrait_parker.png"
        />
        <TestimonialBlock
          quote="Through Wolfia, I'm able to quickly answer large questionnaires for many different clients. Wolfia has a unique approach to questionnaire automation. They use answers, and other sources of unstructured data, to build a living fact sheet about your security and compliance program."
          name="Rob Picard"
          title="Co-founder & CEO · Observa"
          description="Ex-Security Lead at Vanta"
          image="/images/home/landingPage_testimonials_portrait_rob.jpeg"
        />
        <TestimonialBlock
          quote="Wolfia increased the speed of passing security review by 5x. AI compliance has been key for a number of our deals, and Wolfia has helped us turn compliance processes into a strength. We recently did a questionnaire marathon that would have taken 15+ hours without Wolfia!"
          name="David Paffenholz"
          title="Co-founder & CEO · PeopleGPT"
          image="/images/home/landingPage_testimonials_portrait_david.webp"
        />
        <TestimonialBlock
          quote="We've been using Wolfia and AmplifyGRC for our security questionnaires, they save us hours of time, can't imagine life without them."
          name="Angus Parson"
          title="Co-founder & CTO · Solve Intelligence"
          image="/images/home/landingPage_testimonials_portrait_angus.png"
        />
        <TestimonialBlock
          quote="Wolfia and AmplifyGRC have helped expedite security questionnaires and cut times from hours to minutes!"
          name="Gabriel Spencer-Harper"
          title="Co-founder & CEO · Meticulous"
          image="/images/home/landingPage_testimonials_portrait_gabriel.png"
        />
        <TestimonialBlock
          quote="Wolfia helps us turn around security questionnaires in half the time while letting our team focus on higher leverage work."
          name="Will Lawrence"
          title="Co-founder & CEO · Greenlite"
          image="/images/home/landingPage_testimonials_portrait_will.jpeg"
        />
      </div>
    </div>
  </section>
);

const CTABoxSection = () => (
  <Section contentWidth='content-sm' className='text-black px-4 sm:px-6'>
    <CTABox/>
  </Section>
);

export default function LandingPage({ title, url }) {
  return (
    <MarketingPage>
      <Meta
        title="Wolfia - AI Agent for Instant Expert Knowledge for Revenue Teams"
        description="Give superpowers to your sales team with Wolfia’s AI agent that provides instant, expert-quality answers to accelerate your deals."
        keywords="AI Sales Assistant, Expert Knowledge AI, Sales Automation, AI for Sales Teams, Instant Sales Support"
      />
      <HeroContainer title={title} url={url} />
      <TestimonialsModule/>
      <CTABoxSection/>
    </MarketingPage>
  );
}
