import React from 'react';
import Text from '@/components/marketing/Text';
import Section from '@/components/marketing/Section';
import { Calendar } from 'lucide-react';

export const DEMO_URL = 'https://calendar.app.google/wWTmp9suzujfLK9FA';

interface DemoLinkProps {
  children?: React.ReactNode;
}

const DemoLink: React.FC<DemoLinkProps> = ({ children }) => (
  <Section
    contentWidth="content-xs"
    padding="p-none"
    className="m-4 ml-12 mr-12 rounded-xl overflow-hidden relative group"
  >
    <a
      href={DEMO_URL}
      target="_blank"
      rel="noreferrer"
      className="group block w-full"
    >
      <div className="flex items-center gap-3 p-4 bg-silvermist-900 hover:bg-silvermist-800 rounded-lg border border-silvermist-700 transition-colors">
        <Calendar className="w-5 h-5 text-white group-hover:text-white flex-shrink-0 transition-colors" />
        <Text className="flex-grow text-white group-hover:text-white transition-colors">
          {children || "See how our AI agent can give your sales team instant expert knowledge"}
        </Text>
        <span className="flex-shrink-0 px-4 py-2 bg-white text-silvermist-900 text-sm font-semibold rounded-md group-hover:bg-silvermist-50 transition-colors">
          Get a Demo
        </span>
      </div>
    </a>
  </Section>
);

export default DemoLink;