/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/**
 * AIActivity
 */
export interface AIActivity {
  /**
   * Id
   */
  id: string;

  /**
   * Type
   */
  type: 'AI';

  /**
   * Title
   */
  title: string;

  /**
   * Description
   */
  description: string;

  /**
   * Items
   */
  items: AIItem[];

  /**
   * Timestamp
   *
   * @format date-time
   */
  timestamp: string;
}

/**
 * AIAnswerDetailResponse
 */
export interface AIAnswerDetailResponse {
  /**
   * Id
   *
   * @format uuid
   */
  id: string;

  /**
   * Question
   */
  question: string;

  /**
   * Answer
   */
  answer: string;

  /**
   * Confidence
   */
  confidence: number;

  feedback: AnswerFeedback | null;

  /**
   * Created At
   *
   * @format date-time
   */
  created_at: string;

  /**
   * Updated At
   *
   * @format date-time
   */
  updated_at: string;

  /**
   * References
   */
  references?: FactResponse[] | null;
}

/**
 * AIAnswerListItem
 */
export interface AIAnswerListItem {
  /**
   * Id
   *
   * @format uuid
   */
  id: string;

  /**
   * Question
   */
  question: string;

  /**
   * Answer
   */
  answer: string | null;

  /**
   * Created At
   *
   * @format date-time
   */
  created_at: string;
}

/**
 * AIItem
 */
export interface AIItem {
  /**
   * Id
   */
  id: string;

  /**
   * Title
   */
  title: string;

  /**
   * Description
   */
  description: string;
}

/**
 * AccessRequestDisplay
 */
export interface AccessRequestDisplay {
  /**
   * Id
   */
  id: string;

  /**
   * Contact Name
   */
  contact_name: string;

  /**
   * Company Name
   */
  company_name: string;

  /**
   * Email Address
   */
  email_address: string;

  /**
   * Position
   */
  position: string;

  reason: ContactReason;

  /**
   * Organization Name
   */
  organization_name: string;

  /**
   * Has Been Viewed
   */
  has_been_viewed: boolean;

  /**
   * Created At
   *
   * @format date-time
   */
  created_at: string;

  /**
   * Updated At
   *
   * @format date-time
   */
  updated_at: string;

  status: AccessRequestStatus;

  approver: PartialUser | null;
}

/**
 * AccessRequestStatus
 */
export enum AccessRequestStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  DENIED = 'DENIED',
}

/**
 * AccessTokenRequest
 */
export interface AccessTokenRequest {
  /**
   * Access Request Id
   */
  access_request_id: string;
}

/**
 * AccessTokenResponse
 */
export interface AccessTokenResponse {
  /**
   * Token
   */
  token: string;

  /**
   * Expiration
   */
  expiration: string;
}

/**
 * AnswerFeedback
 */
export enum AnswerFeedback {
  THUMBS_UP = 'THUMBS_UP',
  THUMBS_DOWN = 'THUMBS_DOWN',
}

/**
 * AnswerFeedbackRequest
 */
export interface AnswerFeedbackRequest {
  /**
   * Ai Answer Id
   */
  ai_answer_id: string;

  feedback?: AnswerFeedback | null;
}

/**
 * AnswerType
 */
export enum AnswerType {
  MAIN = 'MAIN',
  SECONDARY = 'SECONDARY',
}

/**
 * ApproveAnswerRequest
 */
export interface ApproveAnswerRequest {
  /**
   * Question Id
   *
   * @format uuid
   */
  question_id: string;

  /**
   * Approved
   */
  approved: boolean;
}

/**
 * ApproveAnswerResponse
 */
export interface ApproveAnswerResponse {
  /**
   * Message
   */
  message: string;

  review_status: ReviewStatus;
}

/**
 * AskedWhere
 */
export interface AskedWhere {
  /**
   * Channel Type
   */
  channel_type: string | null;
}

/**
 * AssignDocumentRequest
 */
export interface AssignDocumentRequest {
  /**
   * Assigned User Id
   */
  assigned_user_id?: string | null;
}

/**
 * AssignedActivity
 */
export interface AssignedActivity {
  /**
   * Id
   */
  id: string;

  /**
   * Type
   */
  type: 'Assigned';

  assignedBy: Person;

  assignedTo: Person | null;

  /**
   * Timestamp
   *
   * @format date-time
   */
  timestamp: string;
}

/**
 * AutofillDocumentAnswerDetailResponse
 */
export interface AutofillDocumentAnswerDetailResponse {
  /**
   * Id
   */
  id: string;

  /**
   * Name
   */
  name: string;

  /**
   * Answer
   */
  answer: string;

  answer_type: AnswerType;

  /**
   * Constraints
   */
  constraints?: string[] | null;

  /**
   * Confidence Score
   */
  confidence_score?: number | null;
}

/**
 * AutofillDocumentQuestion
 */
export interface AutofillDocumentQuestion {
  /**
   * Id
   */
  id: string;

  /**
   * Question
   */
  question: string;

  /**
   * Main Answer
   */
  main_answer: string | null;

  /**
   * Approved
   */
  approved: boolean;

  /**
   * Needs Attention
   */
  needs_attention: boolean;

  /**
   * Confidence Score
   */
  confidence_score: number | null;
}

/**
 * AutofillDocumentQuestionDetailResponse
 */
export interface AutofillDocumentQuestionDetailResponse {
  /**
   * Id
   */
  id: string;

  /**
   * Question
   */
  question: string;

  /**
   * Facts
   */
  facts?: FactResponse[] | null;

  /**
   * Answers
   *
   * @default []
   */
  answers?: AutofillDocumentAnswerDetailResponse[];

  /**
   * Approved
   */
  approved: boolean;
}

/**
 * AutofillDocumentQuestionsResponse
 */
export interface AutofillDocumentQuestionsResponse {
  /**
   * Id
   */
  id: string;

  /**
   * Sheets
   */
  sheets: AutofillDocumentSheetQuestionsResponse[];
}

/**
 * AutofillDocumentResponse
 */
export interface AutofillDocumentResponse {
  /**
   * Id
   */
  id: string;

  /**
   * Name
   */
  name: string;

  assignee: UserResponse | null;

  /**
   * Status
   */
  status: string;

  /**
   * Deadline
   */
  deadline: string | null;

  /**
   * Document Type
   */
  document_type: string;

  /**
   * Deal Value
   */
  deal_value: number | null;

  /**
   * Custom Instructions
   */
  custom_instructions: string | null;

  /**
   * Total Questions
   */
  total_questions: number;

  /**
   * Percentage Answered
   */
  percentage_answered: number;

  /**
   * Num Ai Answers
   */
  num_ai_answers: number;

  /**
   * Num High Conf Answers
   */
  num_high_conf_answers: number;

  /**
   * Num Manual Answers
   */
  num_manual_answers: number;

  /**
   * Sheets
   */
  sheets: AutofillSheetResponse[];

  /**
   * Estimated Time Remaining
   */
  estimated_time_remaining?: number | null;
}

/**
 * AutofillDocumentSheetQuestionsResponse
 */
export interface AutofillDocumentSheetQuestionsResponse {
  /**
   * Id
   */
  id: string;

  /**
   * Name
   */
  name: string;

  /**
   * Number
   */
  number: number;

  /**
   * Questions
   */
  questions: AutofillDocumentQuestion[];
}

/**
 * AutofillSheetResponse
 */
export interface AutofillSheetResponse {
  /**
   * Id
   */
  id: string;

  /**
   * Name
   */
  name: string;

  /**
   * Number
   */
  number: number;

  /**
   * Total Questions
   */
  total_questions: number;

  /**
   * Percentage Answered
   */
  percentage_answered: number;

  /**
   * Num Ai Answers
   */
  num_ai_answers: number;

  /**
   * Num High Conf Answers
   */
  num_high_conf_answers: number;

  /**
   * Num Manual Answers
   */
  num_manual_answers: number;
}

/**
 * Body_upload_file_for_autofill
 */
export interface BodyUploadFileForAutofill {
  /**
   * File
   *
   * @format binary
   */
  file: File;

  document_type: DQDocumentType;

  /**
   * Deal Size
   */
  deal_size?: number | null;

  /**
   * Deadline
   */
  deadline?: string | null;

  /**
   * Instruction
   */
  instruction?: string | null;
}

/**
 * Body_upload_file_for_parsing
 */
export interface BodyUploadFileForParsing {
  /**
   * Files
   */
  files: File[];
}

/**
 * ChangeDocumentStatusRequest
 */
export interface ChangeDocumentStatusRequest {
  new_status: DQDocumentStatus;
}

/**
 * ChannelHistoryRescanResponse
 */
export interface ChannelHistoryRescanResponse {
  /**
   * Organization Id
   */
  organization_id: string;

  /**
   * Channel Id
   */
  channel_id: string;

  /**
   * Message
   */
  message: string;
}

/**
 * CommentActivity
 */
export interface CommentActivity {
  /**
   * Id
   */
  id: string;

  /**
   * Type
   */
  type: 'Comment';

  author: Person;

  /**
   * Content
   */
  content: string;

  /**
   * Peopletags
   */
  peopleTags: Person[];

  /**
   * Timestamp
   *
   * @format date-time
   */
  timestamp: string;
}

/**
 * CompanyContent
 */
export interface CompanyContent {
  /**
   * Organization Id
   */
  organization_id: string;

  /**
   * Company Url
   *
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  company_url: string;

  /**
   * Company Name
   */
  company_name: string;

  /**
   * Company Fav Icon
   */
  company_fav_icon?: string | null;

  /**
   * Is Access Enabled
   */
  is_access_enabled: boolean;

  header: Header;

  /**
   * Resources
   */
  resources: ResourceModel[];

  /**
   * Sub Processors
   */
  sub_processors?: SubProcessor[] | null;

  ai_model_architecture: Section;

  ai_model_use: Section;

  data_use_training: Section;

  data_use_inference: Section;

  /**
   * Controls
   */
  controls: ControlCategory[];

  /**
   * Iso Controls
   */
  iso_controls?: ISOControlCategory[] | null;
}

/**
 * ContactReason
 */
export enum ContactReason {
  EXISTING_CUSTOMER = 'EXISTING_CUSTOMER',
  PROSPECTIVE_CUSTOMER = 'PROSPECTIVE_CUSTOMER',
  OTHER = 'OTHER',
}

/**
 * ContentSourceType
 */
export enum ContentSourceType {
  FILE_UPLOAD = 'FILE_UPLOAD',
  WEBSITE_INTEGRATION = 'WEBSITE_INTEGRATION',
  EMAIL_INTEGRATION = 'EMAIL_INTEGRATION',
  SLACK_INTEGRATION = 'SLACK_INTEGRATION',
  GONG_INTEGRATION = 'GONG_INTEGRATION',
  VANTA_INTEGRATION = 'VANTA_INTEGRATION',
  NOTION_INTEGRATION = 'NOTION_INTEGRATION',
}

/**
 * ContentStatus
 */
export enum ContentStatus {
  YET_TO_START = 'YET_TO_START',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
}

/**
 * ControlCategory
 */
export interface ControlCategory {
  /**
   * Category
   */
  category: string;

  /**
   * Items
   */
  items: ControlItem[];

  /**
   * Is Ai Specific
   */
  is_ai_specific: boolean;
}

/**
 * ControlItem
 */
export interface ControlItem {
  /**
   * Name
   */
  name: string;

  /**
   * Summary
   */
  summary: string;

  /**
   * Status
   */
  status: boolean;
}

/**
 * ConversationAnalytics
 */
export interface ConversationAnalytics {
  /**
   * Total Conversations
   */
  total_conversations: number;

  /**
   * Unique Users
   */
  unique_users: number;

  /**
   * Time Series Data
   */
  time_series_data: object[];

  /**
   * Top Users
   */
  top_users: TopUser[];
}

/**
 * ConversationLogItem
 */
export interface ConversationLogItem {
  /**
   * Id
   */
  id: string;

  /**
   * Question
   */
  question: string;

  /**
   * Answer
   */
  answer: string;

  /**
   * User Name
   */
  user_name: string | null;

  /**
   * User Email
   */
  user_email: string | null;

  /**
   * User Profile Image
   */
  user_profile_image: string | null;

  /**
   * Source Type
   */
  source_type: string;

  asked_where: AskedWhere | null;

  /**
   * Answered At
   *
   * @format date-time
   */
  answered_at: string;
}

/**
 * ConversationResponse
 */
export interface ConversationResponse {
  /**
   * Agent Name
   */
  agent_name: string;

  /**
   * Messages
   */
  messages: MessageResponse[];
}

/**
 * CreateCommentRequest
 */
export interface CreateCommentRequest {
  /**
   * Content
   */
  content: string;

  /**
   * Peopletags
   */
  peopleTags?: string[] | null;
}

/**
 * DQDocumentResponse
 */
export interface DQDocumentResponse {
  /**
   * Id
   */
  id: string;

  /**
   * Name
   */
  name: string;

  /**
   * Download Url
   */
  download_url: string;

  status: DQDocumentStatus;

  document_type: DQDocumentType;

  /**
   * Num Of Questions
   */
  num_of_questions: number;

  /**
   * Answered Questions
   */
  answered_questions: number;

  /**
   * Created At
   *
   * @format date-time
   */
  created_at: string;

  /**
   * Updated At
   *
   * @format date-time
   */
  updated_at: string;

  /**
   * Deadline
   */
  deadline: string | null;

  /**
   * Deal Size
   */
  deal_size: number | null;

  /**
   * Instruction
   */
  instruction: string | null;
}

/**
 * DQDocumentStatus
 */
export enum DQDocumentStatus {
  YET_TO_START = 'YET_TO_START',
  QUESTION_EXTRACTION_IN_PROGRESS = 'QUESTION_EXTRACTION_IN_PROGRESS',
  QUESTION_EXTRACTION_COMPLETED = 'QUESTION_EXTRACTION_COMPLETED',
  QUESTION_EXTRACTION_FAILED = 'QUESTION_EXTRACTION_FAILED',
  ANSWERING_IN_PROGRESS = 'ANSWERING_IN_PROGRESS',
  ANSWERING_COMPLETED = 'ANSWERING_COMPLETED',
  USER_ACCEPTED_ANSWER = 'USER_ACCEPTED_ANSWER',
  ANSWERING_FAILED = 'ANSWERING_FAILED',
  MARKED_AS_DELETED = 'MARKED_AS_DELETED',
}

/**
 * DQDocumentType
 */
export enum DQDocumentType {
  RFI = 'RFI',
  RFP = 'RFP',
  SECURITY_QUESTIONNAIRE = 'SECURITY_QUESTIONNAIRE',
  UNCATEGORIZED = 'UNCATEGORIZED',
}

/**
 * DQDocumentsResponse
 */
export interface DQDocumentsResponse {
  /**
   * Documents
   */
  documents: DQDocumentResponse[];
}

/**
 * ExistingReprocessRequest
 */
export interface ExistingReprocessRequest {
  /**
   * Reprocess Before
   */
  reprocess_before?: string | null;

  /**
   * Reprocess Since
   */
  reprocess_since?: string | null;
}

/**
 * Experiments
 */
export interface Experiments {
  /**
   * Enable Chat Interface
   *
   * Enable new chat interface if organization has Slack conversations scanning enabled
   * @default false
   */
  enable_chat_interface?: boolean;

  /**
   * Enable Trust Center
   *
   * Whether the organization has a trust center configured
   * @default false
   */
  enable_trust_center?: boolean;

  /**
   * Enable Insights
   *
   * Enable insights dashboard for admin users to track question analytics
   * @default false
   */
  enable_insights?: boolean;

  /**
   * Installed Slack App
   *
   * Whether the organization has Wolfia app installed on Slack
   * @default false
   */
  installed_slack_app?: boolean;

  /**
   * Has Expert Product Enabled
   *
   * Whether the organization has expert product features enabled
   * @default false
   */
  has_expert_product_enabled?: boolean;
}

/**
 * ExtensionTokenResponse
 */
export interface ExtensionTokenResponse {
  /**
   * Access Token
   */
  access_token: string;

  /**
   * Token Type
   *
   * @default "bearer"
   */
  token_type?: string;
}

/**
 * ExternalIntegrationCreate
 */
export interface ExternalIntegrationCreate {
  /**
   * Provider
   */
  provider: string;

  /**
   * Config
   */
  config: object;
}

/**
 * ExternalIntegrationResponse
 */
export interface ExternalIntegrationResponse {
  /**
   * Id
   */
  id: string;

  /**
   * Provider
   */
  provider: string;

  /**
   * Config
   */
  config: object;

  /**
   * Last Synced At
   */
  last_synced_at: string | null;

  /**
   * Sync Status
   */
  sync_status: string | null;

  /**
   * Created At
   */
  created_at: string;

  /**
   * Updated At
   */
  updated_at: string;
}

/**
 * FactCreate
 */
export interface FactCreate {
  /**
   * Statement
   */
  statement: string;
}

/**
 * FactEdit
 */
export interface FactEdit {
  /**
   * Statement
   */
  statement?: string | null;
}

/**
 * FactReference
 */
export interface FactReference {
  /**
   * Statement
   */
  statement: string;

  source_type: ContentSourceType;

  /**
   * Source Name
   */
  source_name: string;

  /**
   * Last Modified
   */
  last_modified: string;
}

/**
 * FactResponse
 */
export interface FactResponse {
  /**
   * Id
   *
   * @format uuid
   */
  id: string;

  /**
   * Content Source Id
   */
  content_source_id?: string | null;

  /**
   * Statement
   */
  statement: string;

  /**
   * Source Content
   */
  source_content?: string | null;

  /**
   * Source Last Modified
   */
  source_last_modified?: string | null;

  /**
   * Source Download Url
   */
  source_download_url?: string | null;

  /**
   * Source Name
   */
  source_name?: string | null;

  source_type?: ContentSourceType | null;

  file_type?: FileUploadType | null;

  status: FactStatus;

  /**
   * Created At
   *
   * @format date-time
   */
  created_at: string;
}

/**
 * FactReviewFactItem
 */
export interface FactReviewFactItem {
  /**
   * Id
   */
  id: string;

  /**
   * Statement
   */
  statement: string;

  status: FactStatus;

  /**
   * Role
   */
  role: string;

  /**
   * Source Id
   */
  source_id?: string | null;

  /**
   * Source Display Name
   */
  source_display_name?: string | null;

  content_source_type?: ContentSourceType | null;
}

/**
 * FactReviewItem
 */
export interface FactReviewItem {
  /**
   * Id
   */
  id: string;

  /**
   * Recommended Action
   */
  recommended_action?: string | null;

  /**
   * Llm Explanation
   */
  llm_explanation?: string | null;

  /**
   * Partial Coverage
   */
  partial_coverage: boolean;

  /**
   * Is Resolved
   */
  is_resolved: boolean;

  /**
   * Created At
   *
   * @format date-time
   */
  created_at: string;

  /**
   * Resolved By
   */
  resolved_by?: string | null;

  /**
   * Resolved At
   */
  resolved_at?: string | null;
}

/**
 * FactReviewResponse
 */
export interface FactReviewResponse {
  review: FactReviewItem;

  /**
   * Facts
   */
  facts: FactReviewFactItem[];
}

/**
 * FactSourceResponse
 */
export interface FactSourceResponse {
  /**
   * Name
   */
  name: string;

  type: ContentSourceType;

  status: ContentStatus;

  file_type?: FileUploadType | null;

  /**
   * Integration Type
   */
  integration_type?: string | null;

  /**
   * Custom Instructions
   */
  custom_instructions?: string | null;

  /**
   * Facts
   */
  facts: FactResponse[];

  /**
   * Uploaded At
   *
   * @format date-time
   */
  uploaded_at: string;
}

/**
 * FactStatus
 */
export enum FactStatus {
  ACTIVE = 'ACTIVE',
  IN_REVIEW = 'IN_REVIEW',
  DEPRECATED = 'DEPRECATED',
}

/**
 * FileUploadProgressResponse
 */
export interface FileUploadProgressResponse {
  /**
   * Num Of Files
   */
  num_of_files: number;

  /**
   * Total Chunks
   */
  total_chunks: number;

  /**
   * Completed Chunks
   */
  completed_chunks: number;

  /**
   * In Progress Chunks
   */
  in_progress_chunks: number;

  /**
   * Time Remaining In Secs
   */
  time_remaining_in_secs: number;
}

/**
 * FileUploadType
 */
export enum FileUploadType {
  PARSE_QUESTIONNAIRE = 'PARSE_QUESTIONNAIRE',
  PARSE_CONVERSATION = 'PARSE_CONVERSATION',
  PARSE_POLICY = 'PARSE_POLICY',
  PARSE_TECHNICAL = 'PARSE_TECHNICAL',
  PARSE_SALES = 'PARSE_SALES',
}

/**
 * GenerateAnswerRequest
 */
export interface GenerateAnswerRequest {
  /**
   * Question
   */
  question: string;

  /**
   * Style Guide Id
   */
  style_guide_id?: string | null;
}

/**
 * GenerateAnswerResponse
 */
export interface GenerateAnswerResponse {
  /**
   * Id
   */
  id?: string | null;

  /**
   * Found
   */
  found: boolean;

  /**
   * Answer
   */
  answer: string;

  /**
   * Confidence
   */
  confidence: number;

  /**
   * References
   */
  references: FactResponse[];

  feedback?: AnswerFeedback | null;

  /**
   * Style Guide Id
   */
  style_guide_id?: string | null;
}

/**
 * HTTPValidationError
 */
export interface HTTPValidationError {
  /**
   * Detail
   */
  detail?: ValidationError[];
}

/**
 * Header
 */
export interface Header {
  company_logo: Logo;

  /**
   * Company Description
   */
  company_description: string;

  /**
   * Security Point Of Contact
   */
  security_point_of_contact: string;

  /**
   * Security Email
   */
  security_email: string;

  /**
   * Privacy Url
   *
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  privacy_url: string;

  /**
   * Terms Of Service Url
   *
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  terms_of_service_url: string;

  appearance: HeaderAppearance;

  /**
   * Customer Logo Urls
   */
  customer_logo_urls?: Logo[] | null;

  /**
   * Compliance
   */
  compliance?: Logo[] | null;
}

/**
 * HeaderAppearance
 */
export interface HeaderAppearance {
  theme: Theme;

  /**
   * Gradient Start
   */
  gradient_start: string;

  /**
   * Gradient End
   */
  gradient_end: string;
}

/**
 * ISOControlCategory
 */
export interface ISOControlCategory {
  /**
   * Category
   */
  category: string;

  /**
   * Summary
   */
  summary: string;

  /**
   * Items
   */
  items: ISOControlItem[];
}

/**
 * ISOControlItem
 */
export interface ISOControlItem {
  /**
   * Criteria
   */
  criteria: string;

  /**
   * Topic
   */
  topic: string;

  /**
   * Control
   */
  control: string;

  /**
   * Resources
   */
  resources: string[];

  /**
   * Status
   */
  status: boolean;
}

/**
 * IntegrationDetailResponse
 */
export interface IntegrationDetailResponse {
  /**
   * Integration Type
   */
  integration_type: string;

  /**
   * Items
   */
  items: IntegrationItem[];

  pagination: PaginationInfo;
}

/**
 * IntegrationItem
 *
 * One specific source within an integration detail listing.
 * E.g., Slack channel, Email message, Gong call, or uploaded file.
 */
export interface IntegrationItem {
  /**
   * Id
   *
   * @format uuid
   */
  id: string;

  /**
   * Name
   */
  name: string;

  /**
   * Fact Count
   */
  fact_count: number;

  status: ContentStatus;

  /**
   * Last Updated
   *
   * @format date-time
   */
  last_updated: string;

  file_type?: FileUploadType | null;
}

/**
 * IntegrationSummary
 *
 * Represents a single integration's summary info.
 * For example, Slack, Email, Gong, or Core Knowledge (FILE_UPLOAD).
 */
export interface IntegrationSummary {
  /**
   * Integration Type
   */
  integration_type: string;

  /**
   * Provider Name
   */
  provider_name: string;

  /**
   * Status
   */
  status: string;

  /**
   * Source Count
   */
  source_count: number;

  /**
   * Fact Count
   */
  fact_count: number;

  /**
   * Last Updated
   */
  last_updated?: string | null;
}

/**
 * InviteUserRequest
 */
export interface InviteUserRequest {
  /**
   * Email
   *
   * @format email
   */
  email: string;
}

/**
 * ListItem
 */
export interface ListItem {
  /**
   * Description
   */
  description: string;

  /**
   * Is Supported
   */
  is_supported: boolean;
}

/**
 * ListOrganizationUsersResponse
 */
export interface ListOrganizationUsersResponse {
  /**
   * Organization Id
   */
  organization_id: string;

  /**
   * Users
   */
  users: OrganizationUserResponse[];
}

/**
 * ListSlackChannelsResponse
 */
export interface ListSlackChannelsResponse {
  /**
   * Organization Id
   */
  organization_id: string;

  /**
   * Channels
   */
  channels: SlackChannelResponse[];
}

/**
 * Logo
 */
export interface Logo {
  /**
   * Url
   *
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  url: string;

  /**
   * Alt
   */
  alt: string;

  /**
   * Height
   */
  height: number;

  /**
   * Width
   */
  width: number;
}

/**
 * MessageRequest
 */
export interface MessageRequest {
  /**
   * Message
   */
  message: string;
}

/**
 * MessageResponse
 */
export interface MessageResponse {
  /**
   * Id
   *
   * @format uuid
   */
  id: string;

  /**
   * Content
   */
  content: string;

  /**
   * Sender Name
   */
  sender_name: string;

  /**
   * Created At
   *
   * @format date-time
   */
  created_at: string;

  /**
   * Is Ai Response
   */
  is_ai_response: boolean;

  /**
   * Citations
   */
  citations: Record<string, FactReference> | null;

  /**
   * Follow Ups
   */
  follow_ups: string[] | null;
}

/**
 * OrganizationCreate
 */
export interface OrganizationCreate {
  /**
   * Name
   *
   * @minLength 1
   * @maxLength 100
   */
  name: string;

  /**
   * Image
   */
  image?: string | null;

  /**
   * Hd
   */
  hd?: string | null;
}

/**
 * OrganizationEdit
 */
export interface OrganizationEdit {
  /**
   * Name
   */
  name?: string | null;

  /**
   * Hd
   */
  hd?: string | null;
}

/**
 * OrganizationModel
 */
export interface OrganizationModel {
  /**
   * Id
   */
  id: string;

  /**
   * Name
   */
  name: string;

  /**
   * Hd
   */
  hd?: string | null;
}

/**
 * OrganizationOverviewCreate
 */
export interface OrganizationOverviewCreate {
  /**
   * Overview
   */
  overview?: string | null;

  /**
   * Product Overview
   */
  product_overview?: string | null;

  /**
   * Tech Overview
   */
  tech_overview?: string | null;

  /**
   * Competitive Edge
   */
  competitive_edge?: string | null;

  /**
   * Internal Teams
   */
  internal_teams?: string | null;

  /**
   * Target Customer
   */
  target_customer?: string | null;

  /**
   * Abbreviations
   */
  abbreviations?: string | null;
}

/**
 * OrganizationOverviewResponse
 */
export interface OrganizationOverviewResponse {
  /**
   * Id
   */
  id: string;

  /**
   * Organization Id
   */
  organization_id: string;

  /**
   * Overview
   */
  overview: string | null;

  /**
   * Product Overview
   */
  product_overview: string | null;

  /**
   * Tech Overview
   */
  tech_overview: string | null;

  /**
   * Competitive Edge
   */
  competitive_edge: string | null;

  /**
   * Internal Teams
   */
  internal_teams: string | null;

  /**
   * Target Customer
   */
  target_customer: string | null;

  /**
   * Abbreviations
   */
  abbreviations: string | null;

  /**
   * Created At
   *
   * @format date-time
   */
  created_at: string;

  /**
   * Updated At
   *
   * @format date-time
   */
  updated_at: string;
}

/**
 * OrganizationResponse
 */
export interface OrganizationResponse {
  /**
   * Id
   */
  id: string;

  /**
   * Name
   */
  name: string;

  /**
   * Image
   */
  image: string | null;

  /**
   * Hd
   */
  hd: string | null;
}

/**
 * OrganizationUserResponse
 */
export interface OrganizationUserResponse {
  /**
   * Id
   */
  id: string;

  /**
   * Email
   */
  email: string;

  /**
   * Name
   */
  name: string;

  /**
   * Username
   */
  username: string;

  role: UserRole;

  /**
   * Is Internal
   */
  is_internal: boolean;

  /**
   * Profile Image
   */
  profile_image: string | null;
}

/**
 * OutputFormat
 */
export enum OutputFormat {
  Docx = 'docx',
  Original = 'original',
  Pdf = 'pdf',
  Xlsx = 'xlsx',
}

/**
 * PaginatedConversationLogs
 */
export interface PaginatedConversationLogs {
  /**
   * Data
   */
  data: ConversationLogItem[];

  /**
   * Total
   */
  total: number;

  /**
   * Page
   */
  page: number;

  /**
   * Page Size
   */
  page_size: number;

  /**
   * Total Pages
   */
  total_pages: number;
}

/**
 * PaginatedFactResponse
 */
export interface PaginatedFactResponse {
  /**
   * Data
   */
  data: FactResponse[];

  /**
   * Total
   */
  total: number;

  /**
   * Page
   */
  page: number;

  /**
   * Page Size
   */
  page_size: number;

  /**
   * Total Pages
   */
  total_pages: number;
}

/**
 * PaginationInfo
 */
export interface PaginationInfo {
  /**
   * Total
   */
  total: number;

  /**
   * Limit
   */
  limit: number;

  /**
   * Offset
   */
  offset: number;

  /**
   * Has Next
   */
  has_next: boolean;
}

/**
 * PartialUser
 */
export interface PartialUser {
  /**
   * Id
   */
  id: string;

  /**
   * Name
   */
  name: string;

  /**
   * Profile Image
   */
  profile_image: string | null;

  /**
   * Username
   */
  username: string | null;
}

/**
 * Person
 */
export interface Person {
  /**
   * Id
   */
  id: string;

  /**
   * Name
   */
  name: string;

  /**
   * Email
   */
  email: string;

  /**
   * Profileimage
   */
  profileImage: string | null;
}

/**
 * PortalAccessRequest
 */
export interface PortalAccessRequest {
  /**
   * Contact Name
   */
  contact_name: string;

  /**
   * Company Name
   */
  company_name?: string | null;

  /**
   * Position
   */
  position?: string | null;

  /**
   * Email Address
   *
   * @format email
   */
  email_address: string;

  reason: ContactReason;

  /**
   * Organization Id
   */
  organization_id: string;
}

/**
 * QuestionnaireResponse
 */
export interface QuestionnaireResponse {
  /**
   * File Id
   */
  file_id: string;

  /**
   * Message
   */
  message: string;
}

/**
 * RegenerateAnswersRequest
 */
export interface RegenerateAnswersRequest {
  /**
   * Instructions
   */
  instructions?: string | null;
}

/**
 * RegenerateFactsRequest
 */
export interface RegenerateFactsRequest {
  /**
   * Content Id
   */
  content_id: string;

  /**
   * Instruction
   */
  instruction: string;
}

/**
 * RenameDocumentRequest
 */
export interface RenameDocumentRequest {
  /**
   * Name
   */
  name: string;
}

/**
 * ReprocessChannelsRequest
 */
export interface ReprocessChannelsRequest {
  /**
   * Channel Ids
   */
  channel_ids: string[];

  /**
   * Reset Ingestion
   *
   * @default false
   */
  reset_ingestion?: boolean;
}

/**
 * ReprocessChannelsResponse
 */
export interface ReprocessChannelsResponse {
  /**
   * Organization Id
   */
  organization_id: string;

  /**
   * Channels Queued
   */
  channels_queued: number;

  /**
   * Message
   */
  message: string;
}

/**
 * ResolutionType
 */
export enum ResolutionType {
  AcceptNew = 'accept_new',
  RejectNew = 'reject_new',
  EditNew = 'edit_new',
}

/**
 * ResolveReviewRequest
 */
export interface ResolveReviewRequest {
  resolution_type: ResolutionType;

  /**
   * Edited Statement
   */
  edited_statement?: string | null;
}

/**
 * ResourceModel
 */
export interface ResourceModel {
  /**
   * Type
   */
  type: string;

  /**
   * Title
   */
  title: string;

  /**
   * Description
   */
  description: string;

  /**
   * Is Public
   */
  is_public: boolean;

  /**
   * Url
   */
  url?: string | null;
}

/**
 * Response
 */
export interface Response {
  /**
   * Message
   */
  message: string;
}

/**
 * ResumeAnswersRequest
 */
export interface ResumeAnswersRequest {
  /**
   * Instructions
   */
  instructions?: string | null;
}

/**
 * ReviewStatus
 */
export enum ReviewStatus {
  NOT_REVIEWED = 'NOT_REVIEWED',
  REVIEWED = 'REVIEWED',
}

/**
 * Section
 */
export interface Section {
  /**
   * Title
   */
  title?: string | null;

  /**
   * Summary
   */
  summary?: string | null;

  /**
   * Logos
   *
   * @default []
   */
  logos?: Logo[];

  /**
   * Items
   *
   * @default []
   */
  items?: ListItem[];

  /**
   * Is Verified
   *
   * @default false
   */
  is_verified?: boolean;
}

/**
 * SlackChannelResponse
 */
export interface SlackChannelResponse {
  /**
   * Id
   */
  id: string;

  /**
   * Channel Id
   */
  channel_id: string;

  /**
   * Name
   */
  name: string;

  /**
   * Is Monitoring
   */
  is_monitoring: boolean;

  /**
   * Monitoring Enabled By
   */
  monitoring_enabled_by: string | null;

  /**
   * Monitoring Enabled At
   */
  monitoring_enabled_at: string | null;
}

/**
 * SlackChannelScanResponse
 */
export interface SlackChannelScanResponse {
  /**
   * Organization Id
   */
  organization_id: string;

  /**
   * Message
   */
  message: string;
}

/**
 * SlackPreferencesResponse
 */
export interface SlackPreferencesResponse {
  /**
   * Organization Id
   */
  organization_id: string;

  /**
   * Scan Slack Conversations
   */
  scan_slack_conversations: boolean;

  /**
   * Scan Slack Channels
   */
  scan_slack_channels: boolean;

  /**
   * Scan Slack Users
   */
  scan_slack_users: boolean;
}

/**
 * SlackUserScanResponse
 */
export interface SlackUserScanResponse {
  /**
   * Organization Id
   */
  organization_id: string;

  /**
   * Message
   */
  message: string;
}

/**
 * Standard
 */
export enum Standard {
  ISO42001 = 'ISO-42001',
  NISTAI = 'NIST-AI',
  CCPA = 'CCPA',
  SOC2 = 'SOC2',
  ISO27001 = 'ISO-27001',
  OWASP = 'OWASP',
  GDPR = 'GDPR',
  HIPAA = 'HIPAA',
  FedRAMP = 'FedRAMP',
  PCIDSS = 'PCI-DSS',
}

/**
 * SubProcessor
 */
export interface SubProcessor {
  /**
   * Name
   */
  name: string;

  /**
   * Purpose
   */
  purpose: string;

  /**
   * Location
   */
  location: string[];

  logo: Logo;

  /**
   * Standards
   */
  standards?: Standard[] | null;
}

/**
 * SuggestedQuestionsResponse
 */
export interface SuggestedQuestionsResponse {
  /**
   * Questions
   */
  questions: string[];
}

/**
 * Theme
 */
export enum Theme {
  Light = 'light',
  Dark = 'dark',
}

/**
 * TimeFrame
 */
export enum TimeFrame {
  Week = 'week',
  Month = 'month',
  Year = 'year',
}

/**
 * TopUser
 */
export interface TopUser {
  /**
   * Name
   */
  name: string;

  /**
   * Email
   */
  email: string;

  /**
   * Conversation Count
   */
  conversation_count: number;
}

/**
 * UpdateAnswerRequest
 */
export interface UpdateAnswerRequest {
  /**
   * Answer Text
   */
  answer_text: string;

  review_status?: ReviewStatus | null;
}

/**
 * UpdateAnswerResponse
 */
export interface UpdateAnswerResponse {
  /**
   * Message
   */
  message: string;

  /**
   * Current Answer
   */
  current_answer?: string | null;
}

/**
 * UpdateChannelMonitoringRequest
 */
export interface UpdateChannelMonitoringRequest {
  /**
   * Is Monitoring
   */
  is_monitoring: boolean;
}

/**
 * UpdateChannelMonitoringResponse
 */
export interface UpdateChannelMonitoringResponse {
  /**
   * Organization Id
   */
  organization_id: string;

  /**
   * Channel Id
   */
  channel_id: string;

  /**
   * Is Monitoring
   */
  is_monitoring: boolean;

  /**
   * Monitoring Enabled By
   */
  monitoring_enabled_by: string | null;

  /**
   * Monitoring Enabled At
   */
  monitoring_enabled_at: string | null;

  /**
   * Message
   */
  message: string;
}

/**
 * UpdateCommentRequest
 */
export interface UpdateCommentRequest {
  /**
   * Content
   */
  content?: string | null;

  /**
   * Peopletags
   */
  peopleTags?: string[] | null;
}

/**
 * UpdateSlackPreferencesRequest
 */
export interface UpdateSlackPreferencesRequest {
  /**
   * Scan Slack Conversations
   */
  scan_slack_conversations: boolean;

  /**
   * Scan Slack Channels
   */
  scan_slack_channels: boolean;

  /**
   * Scan Slack Users
   */
  scan_slack_users: boolean;
}

/**
 * UpdateUserRoleRequest
 */
export interface UpdateUserRoleRequest {
  /**
   * User Id
   */
  user_id: string;

  role: UserRole;
}

/**
 * UpdateUserRoleResponse
 */
export interface UpdateUserRoleResponse {
  /**
   * User Id
   */
  user_id: string;

  role: UserRole;

  /**
   * Message
   */
  message: string;
}

/**
 * UpdatedActivity
 */
export interface UpdatedActivity {
  /**
   * Id
   */
  id: string;

  /**
   * Type
   */
  type: 'Updated';

  updatedBy: Person;

  /**
   * Updatedfield
   */
  updatedField: string;

  /**
   * Oldvalue
   */
  oldValue: null;

  /**
   * Newvalue
   */
  newValue: null;

  /**
   * Changesummary
   */
  changeSummary: null;

  /**
   * Timestamp
   *
   * @format date-time
   */
  timestamp: string;
}

/**
 * UploadContentRequest
 */
export interface UploadContentRequest {
  /**
   * Content
   */
  content: string;
}

/**
 * UploadedActivity
 */
export interface UploadedActivity {
  /**
   * Id
   */
  id: string;

  /**
   * Type
   */
  type: 'Uploaded';

  uploadedBy: Person;

  /**
   * Documentname
   */
  documentName: string;

  /**
   * Timestamp
   *
   * @format date-time
   */
  timestamp: string;
}

/**
 * UrlSelectionRequest
 */
export interface UrlSelectionRequest {
  /**
   * Job Id
   */
  job_id: string;

  /**
   * Selected Urls
   */
  selected_urls: string[];

  /**
   * Org Id
   */
  org_id?: string | null;

  /**
   * Original Url
   *
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  original_url: string;
}

/**
 * UrlSelectionResponse
 */
export interface UrlSelectionResponse {
  /**
   * Status
   */
  status: string;

  /**
   * Message
   */
  message: string;

  /**
   * Urls
   */
  urls: string[];
}

/**
 * UserEdit
 */
export interface UserEdit {
  /**
   * Name
   */
  name?: string | null;

  /**
   * Email
   */
  email?: string | null;

  /**
   * Username
   */
  username?: string | null;
}

/**
 * UserResponse
 */
export interface UserResponse {
  /**
   * Id
   */
  id: string;

  /**
   * Email
   */
  email: string;

  /**
   * Name
   */
  name: string;

  /**
   * Username
   */
  username: string | null;

  status: UserStatus;

  /**
   * Profile Image
   */
  profile_image: string | null;

  /**
   * Organization Id
   */
  organization_id: string | null;

  /**
   * Organization Name
   */
  organization_name: string | null;

  /**
   * Organization Domain
   */
  organization_domain: string | null;

  organization_user_role: UserRole;

  /**
   * Organization Logo
   */
  organization_logo: string | null;

  /**
   * Is Internal
   */
  is_internal: boolean;

  experiments: Experiments;
}

/**
 * UserRole
 */
export enum UserRole {
  ADMIN = 'ADMIN',
  EXPERT = 'EXPERT',
  USER = 'USER',
}

/**
 * UserStatus
 */
export enum UserStatus {
  ONBOARDING_CREATE_ORG = 'ONBOARDING_CREATE_ORG',
  ONBOARDING_ADD_SOURCES = 'ONBOARDING_ADD_SOURCES',
  ONBOARDING_ASK_QUESTION = 'ONBOARDING_ASK_QUESTION',
  ACTIVE = 'ACTIVE',
  DEACTIVATED = 'DEACTIVATED',
}

/**
 * UserStyleGuideInput
 */
export interface UserStyleGuideInput {
  /**
   * Style Guide
   */
  style_guide: string;
}

/**
 * UserStyleGuideResponse
 */
export interface UserStyleGuideResponse {
  /**
   * Style Guide
   */
  style_guide?: string | null;
}

/**
 * UserUpdatedAnswerRequest
 */
export interface UserUpdatedAnswerRequest {
  /**
   * Ai Answer Id
   */
  ai_answer_id: string;

  /**
   * User Updated Answer
   */
  user_updated_answer: string;
}

/**
 * ValidationError
 */
export interface ValidationError {
  /**
   * Location
   */
  loc: (string | number)[];

  /**
   * Message
   */
  msg: string;

  /**
   * Error Type
   */
  type: string;
}

/**
 * WebsiteCrawlRequest
 */
export interface WebsiteCrawlRequest {
  /**
   * Url
   *
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  url: string;

  /**
   * Search
   */
  search?: string | null;
}

/**
 * WebsiteCrawlResponse
 */
export interface WebsiteCrawlResponse {
  /**
   * Job Id
   */
  job_id: string;

  /**
   * Message
   */
  message: string;

  /**
   * Original Url
   *
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  original_url: string;
}
