import Head from 'next/head';
import { ReactNode } from 'react';

interface Props {
  title: string;
  favicon?: string;
  children?: ReactNode;
  description?: string;
  keywords?: string;
}

export function Meta({ title, favicon, description, children, keywords }: Props) {
  return (
    <Head>
      <title>{title ?? 'Wolfia - AI Agent for Instant Expert Knowledge for Revenue Teams'}</title>
      {
        favicon ? (
          <link rel="icon" href={favicon} />
        ) : (
          <>
            <link rel="icon" href="/favicon.ico" sizes="any" />
            <link rel="icon" href="/images/meta/icon.svg" type="image/svg+xml" />
            <link rel="apple-touch-icon" href="/images/meta/apple-touch-icon.png" />
            <link rel="manifest" href="/site.webmanifest" />
          </>
        )
      }
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="description" content={description ?? 'Give superpowers to your sales team with Wolfia’s AI agent that provides instant, expert-quality answers to accelerate your deals.'} />
      {keywords && <meta name="keywords" content={keywords} />}

      <meta property="og:type" content="website" />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description ?? 'Give superpowers to your sales team with Wolfia’s AI agent that provides instant, expert-quality answers to accelerate your deals.'} />
      <meta property="og:url" content="https://wolfia.com" />
      <meta property="og:site_name" content="Wolfia" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta property="og:author" content="Wolfia Inc." />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content="@wolfia_app" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description ?? 'Give superpowers to your sales team with Wolfia’s AI agent that provides instant, expert-quality answers to accelerate your deals.'} />

      {children}
    </Head>
  );
}
