import React from 'react';
import TrustCenterPage from '../components/trust/TrustCenterPage';
import LandingPage from '../components/marketing/LandingPage';
import { getAllBlogPostSummaries } from '@/components/blog/utils';
import { getAllCaseStudySummaries } from '@/components/customers/utils';

type ContentType = 'blog' | 'case-studies';

interface Props {
  subdomain: string;
  title: string;
  url: string;
}

export async function getServerSideProps(context: { req: { headers: { host: string } } }) {
  const { req } = context;
  const host = req.headers.host;
  const subdomain = host.split('.')[0];

  const latestBlogPost = getAllBlogPostSummaries()[0];
  const latestCaseStudy = getAllCaseStudySummaries()[0];

  const blogDate = new Date(latestBlogPost.date);
  const caseStudyDate = new Date(latestCaseStudy.publishDate);

  const contentType: ContentType = caseStudyDate > blogDate ? 'case-studies' : 'blog';
  const latestPost = contentType === 'case-studies' ? latestCaseStudy : latestBlogPost;

  const title = latestPost.title;
  const url = `/${contentType}/${latestPost.slug}`;

  return {
    props: {
      subdomain,
      title,
      url,
    },
  };
}

const MainPage = ({ subdomain, title, url }: Props) => {
  const trustCenterSubdomains = ['juicebox', 'fini', 'mendable', 'lancey', 'solveintelligence', 'greenlite', 'asklio'];

  if (trustCenterSubdomains.includes(subdomain)) {
    return <TrustCenterPage companyName={subdomain} />;
  }

  return <LandingPage title={title} url={url} />;
};

export default MainPage;